<template>
  <div>
    <Pie :chart-options="chartOptions" :chart-data="chartData" :width="300" :height="300" />
  </div>
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  // Title,
  Tooltip,
  // Legend,
  ArcElement
  // CategoryScale,
  // LinearScale,
  // BarElement,
} from "chart.js";
ChartJS.register(
  // Title,
  Tooltip,
  // Legend,
  ArcElement
  // CategoryScale,
  // LinearScale,
  // BarElement
);
export default {
  props: ["chartOptions", "chartData"],
  components: {
    Pie
  },
  mounted() {
    this.chartData.datasets.forEach(dataset => {
      dataset.backgroundColor = dataset.backgroundColor.map(color => {
        if (color === undefined) {
          return "#7a3896";
        } else {
          return color;
        }
      });
    });
  }
};
</script>

<style></style>
